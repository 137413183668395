@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 222.2 84% 4.9%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;

        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;

        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;

        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;

        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;

        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;

        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 212.7 26.8% 83.9%;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }
}

@font-face {
    font-family: 'Kavoon';
    src: url('./assets/font/Kavoon/Kavoon-Regular.ttf') format('truetype');
}

.font-kavoon {
    background-image: url('./assets/images/webp/background.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bg-Group133 {
    background-image: url('./assets/images/webp/Group133.webp');
    @media screen and (max-width: 768px) {
        background-image: url('./assets/images/webp/Group133Mobile.webp');
        background-position: center top;
    }
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% 100%;
    aspect-ratio: 384 / 221;
}
.bg-Group136 {
    background-image: url('./assets/images/webp/Group136.webp');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 100%;
}
.bg-Group134 {
    background-image: url('./assets/images/webp/Group134.webp');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 100%;
}
.bg-Group135 {
    background-image: url('./assets/images/webp/Group135.webp');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 100%;
}
.text-stroke {
    color: #ffc123;
    -webkit-text-fill-color: #ffc123;
    -webkit-text-stroke: 2px #933805;
    text-stroke: 2px #933805;
    font-size: 56px;
    color: transparent;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.table-market {
    border-collapse: separate;
    border-spacing: 0 11px;
}

.table-market td {
    border: 2px solid #16202d;
    border-style: solid none;
    padding: 11px;
    background-color: #16202d;
}

.table-market td:first-child {
    border-left-style: solid;
    border-top-left-radius: 11px;
    border-bottom-left-radius: 11px;
}

.table-market td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 11px;
    border-top-right-radius: 11px;
}
.table-wrapper {
    display: grid;
    /* grid-template-columns: repeat(1, minmax(0, 1fr)); */
    overflow: auto;
    white-space: nowrap;
}
.button-storke {
    color: #ffc123;
    -webkit-text-fill-color: #ffc123;
    -webkit-text-stroke: 2px #933805;
    text-stroke: 2px #933805;
    font-size: 36px;
    color: transparent;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 2px solid #a751fd;
    border-radius: 100px;
    padding: 10px 40px;
    background-color: rgba(255, 255, 255, 0.1);
}

.main-button {
    background: linear-gradient(90deg, #ffb344 -2.67%, #ec713a 100%) !important;
    border-radius: 10px !important;
    border: 1px solid #000 !important;
    color: white !important;
    height: 40px;
}

.main-button-primary {
    background: #282c34 !important;
    border-radius: 10px !important;
    color: white !important;
    height: 40px;
}

.main-button-green {
    background: linear-gradient(90deg, #688f3e -2.67%, #4c692f 100%) !important;
    height: 40px;
    border-radius: 10px !important;
    color: white !important;
    font-size: 16px;
}

.button-save-setting {
    background: linear-gradient(90deg, #688f3e -2.67%, #4c692f 100%) !important;
}

.button-save-address {
    background: linear-gradient(90deg, #de5d15 -2.67%, #d92626 100%) !important;
}

.slider-custom .slick-track {
    display: flex;
    align-items: center;
}

.style-scroll-hidden::-webkit-scrollbar {
    display: none;
}

.style-scroll::-webkit-scrollbar {
    height: 4px;
    border-radius: 4px;
}

.style-scroll::-webkit-scrollbar-track {
    background-color: #16202d;
}

.style-scroll::-webkit-scrollbar-thumb {
    background-color: #60708a;
    height: 4px;
    border-radius: 4px;
}

.text-secondary {
 color:rgb(142, 151, 168)
}


.border-secondary {
    border-color: rgb(142, 151, 168)
}

